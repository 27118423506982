import {
  Outlet,
  Params,
  useLocation,
  useNavigate,
  useParams
} from 'react-router';
import { FC, useEffect } from 'react';

import {
  AGENTS_NAVIGATION_EVENT,
  Loader,
  MAIN_ROUTE,
  STUDIO_NAVIGATION_EVENT
} from '../shared';

import { personaStore } from 'entities/persona';
import { avatarStore } from 'entities/avatar';

import { Call } from 'pages/call';
import { CallEmbed } from 'pages/embed/ui/CallEmbed';
import { useAuthStore } from 'stores/useAuthStore';
import useVoiceStore from 'stores/useVoiceStore';
import { creditStore, features } from 'entities/credit';
import { videoCallStore } from 'features/livekit-call';
import { userStore } from 'entities/user';
import { AppProps } from './App';

export const AuthLoading = (props: AppProps) => {
  const { isLoading } = userStore();
  const { updateAuthStore } = useAuthStore();
  const { fetchAvatars, updateAvatarStore, customTalkingPhotos } =
    avatarStore();
  const { fetchVoices } = useVoiceStore();
  const { fetchPersonas } = personaStore();
  const loc = useLocation();

  useEffect(() => {
    fetchAvatars();
    fetchPersonas();
    fetchVoices();
  }, []);

  // * Pass props from the main app

  const { updateCreditStore, toggleFeatureBlockModal } = creditStore();

  useEffect(() => {
    updateCreditStore('toggleBuyCreditsModal', () =>
      props.toggleBuyCreditsModal(true)
    );
    updateCreditStore('toggleUpgradeModal', () =>
      props.toggleUpgradeModal(true)
    );
    updateCreditStore(
      'toggleFeatureBlockModal',
      (open: boolean | typeof features[number]) =>
        props.toggleFeatureBlockModal(open)
    );
    updateAvatarStore('toggleUploadAvatar', () => props.toggleUploadAvatar());

    updateAuthStore('toggleCreateAccModal', () => props.toggleCreateAccModal());
  }, []);

  useEffect(() => {
    updateCreditStore('isChatAvailable', props.isChatAvailable);
  }, [props.isChatAvailable]);

  useEffect(() => {
    updateCreditStore('productName', props.productName);
  }, [props.productName]);

  useEffect(() => {
    updateCreditStore('premiumVoicesAllowed', props.premiumVoicesAllowed);
  }, [props.premiumVoicesAllowed]);

  useEffect(() => {
    updateAvatarStore('talkingPhotos', props.talkingPhotos);
  }, [props.talkingPhotos]);

  useEffect(() => {
    if (customTalkingPhotos && customTalkingPhotos.length > 0) {
      fetchAvatars();
    }
  }, [props.customTalkingPhotos]);

  useEffect(() => {
    updateAuthStore('apiKey', props.apiKey);
  }, [props.apiKey]);

  useEffect(() => {
    updateAuthStore('agentKey', props.agentKey);
  }, [props.agentKey]);

  // * Update call menu functions in studio app

  const { persona, setMessages } = videoCallStore();
  const { togglePersonaEmbedModal, setPersonaShareModal } = personaStore();

  const agentsParams = useParams();

  useEffect(() => {
    if (persona && persona.id === agentsParams.id) {
      props.updateCallStore('shareAgent', () => setPersonaShareModal(persona));

      props.updateCallStore('embedAgent', () => {
        if (
          props.productName.toLowerCase() === 'plus' ||
          props.productName.toLowerCase() === 'premium'
        ) {
          togglePersonaEmbedModal(persona.id);
        } else {
          toggleFeatureBlockModal('embed');
        }
      });

      props.updateCallStore('startNewChat', () => setMessages([]));
    }
  }, [persona]);

  // * Handle routing communication with studio

  /**
   * AuthLoading component is responsible for handling the initial loading and setup of the application.
   * It fetches avatars, personas, and voices, and updates the necessary stores.
   * It also handles routing communication with the studio app.
   *
   * @param {AppProps} props - The props for the AuthLoading component.
   * @returns {JSX.Element} The rendered AuthLoading component.
   */

  const agentsLocation = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    /**
     * Main App (Studio) Navigation event handler.
     * It is used as a callback for STUDIO_NAVIGATION_EVENT listener
     * to syncronize routing between apps:
     * When the location is changed in studio, agents updates router state,
     * so they are on the same page (pun intended).
     *
     * @param {Event} event - JS Event.
     */
    function studioNavigationHandler(event: Event) {
      const { location, params } = (
        event as CustomEvent<{
          location: Location;
          params: Readonly<Params<string>>;
        }>
      ).detail;

      if (
        location.pathname === agentsLocation.pathname &&
        location.pathname !== MAIN_ROUTE
      ) {
        return;
      }

      navigate(location.pathname + location.search);
    }

    window.addEventListener(STUDIO_NAVIGATION_EVENT, studioNavigationHandler);

    return () => {
      window.removeEventListener(
        STUDIO_NAVIGATION_EVENT,
        studioNavigationHandler
      );
    };
  }, [agentsLocation, agentsParams]);

  useEffect(() => {
    window.dispatchEvent(
      new CustomEvent(AGENTS_NAVIGATION_EVENT, {
        detail: { location: agentsLocation, params: agentsParams }
      })
    );
  }, [agentsLocation, agentsParams, agentsParams.id]);

  if (loc.pathname.includes('embed')) {
    return <CallEmbed />;
  }

  if (isLoading)
    return (
      <div className="ag-w-full ag-h-screen ag-bg-white ag-flex ag-items-center ag-justify-center">
        <Loader size={96} />
      </div>
    );

  if (loc.pathname.includes('call') && loc.search.includes('shared')) {
    return <Call />;
  }

  return <Outlet />;
};
