import { AxiosResponse } from 'axios';
import { tavusHttpService } from 'shared/api';
import { ListPersonas, ITavusPersona } from '../model';

const tavusPersonasEndpoint = '/personas';

export const tavusPersonaApi = {
  fetch: async (id: string) => {
    const { data }: AxiosResponse<ITavusPersona> = await tavusHttpService.get(
      `${tavusPersonasEndpoint}/${id}`
    );

    return data;
  },
  fetchAll: async () => {
    const { data }: AxiosResponse<ListPersonas> = await tavusHttpService.get(
      tavusPersonasEndpoint + '?persona_type=user'
    );

    return data.data;
  }
};
